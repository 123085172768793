<template>
  <div>
    <a v-on:click="handleClick" :class="{ selected: isSelected }">
      {{ label }}
      <span v-if="count">({{ count }})</span>
    </a>
  </div>
</template>

<script>
export default {
  props: { label: String, value: String, isSelected: Boolean, count: Number },
  methods: {
    handleClick() {
      this.$emit("filterOptionChanged", this.value);
    }
  }
};
</script>

<style scoped>
a {
  display: inline-block;
  position: relative;
  right: 16px;
  padding-left: 16px;
  font-size: 14px;
  line-height: 30px;
  color: #5c4646;
  user-select: none;
  transition: none; /* Used to override global style for `a` elements */
}

a:hover,
a:active {
  color: #b95652;
}

.selected {
  color: #b95652;
  font-weight: bold;
}

.selected::before {
  position: absolute;
  left: 0;
  content: "\2713";
}
</style>