console.log("Search filter loaded!");
import axios from "axios";
import { updateCurrencyValues } from "./currency";

const destinationCheckboxes = document.querySelectorAll(
  ".filter-destinations .filter-checkbox-input"
);
console.log("CHECKBOXES", destinationCheckboxes);

destinationCheckboxes.forEach(checkbox => {
  checkbox.addEventListener("change", e => {
    const value = e.currentTarget.checked;
    updateFilters("destinations", checkbox.id, value);
    search();
  });
});

var filters = {};
function updateFilters(group, filterName, filterValue) {
  if (!filters.hasOwnProperty(group)) {
    filters[group] = [];
  }
  const existing = filters[group].find(({ name }) => name === filterName);
  if (existing) {
    existing.value = filterValue;
  } else {
    filters[group].push({ name: filterName, value: filterValue });
  }
  console.log("Updating filter!", filterName, filterValue);
  console.log("Filters:", filters);
}

function buildQueryFromFilters() {
  const queryObject = {
    query: {
      bool: {
        should: []
      }
    }
  };

  filters.destinations.forEach(({ name, value }) => {
    if (value) {
      queryObject.query.bool.should.push({
        match: { tour_countries: name }
      });
    }
  });

  return queryObject;
}

function search() {
  const query = buildQueryFromFilters();
  console.log("SEARCH QUERY:", JSON.stringify(query));
  const resultsTarget = document.querySelector(".js-search-results-target");

  resultsTarget.innerHTML = "Loading...";
  axios
    .post(
      "https://europe-west1-elasticsafari.cloudfunctions.net/abs_search_advanced",
      query
    )
    .then(function(response) {
      console.log(response);

      const results = response.data.search_results.map(raw => ({
        title: raw._source.tour_sectitle
      }));

      resultsTarget.innerHTML = results.reduce((html, result) => {
        return (
          html +
          `
          <div class="list-card product" data-entry-id="40452" id="tour_40452">
            <div class="list-card-wrapper">
              <div class="list-card-img-map">                                                                     
                <a class="list-card-img" href="https://www-abs/budget-lodge-safaris/full-day-cape-town-big-five-budget-safari/">
                  <img src="https://d2iaf7xwaf71rg.cloudfront.net/31983/rhinos_01__medium.jpg" alt="">
                </a>                                        
                <div class="list-card-map">
                  <a href="#route_map" data-json="/_tour_includes/gmap/31974/" class="fancymap_global"><span class="hide-xs">View</span> Map</a>
                  <img itemprop="image" src="/assets/2019_responsive_update/img/map.png" alt="">
                </div>                  
              </div>
              <div class="list-card-info-wrapper">
                <h4>${result.title}</h4>
                <div class="list-card-details"></div>
                <div class="list-card-price-actions price">
                  <div class="list-card-price indicative">
                    <p class="list-card-from-price">01 days from</p>
                    <p class="list-card-price-now">
                      <span class="js-currency" data-currency="ZAR" data-amount="3060" data-special-offer-entry-id="31983"></span>
                      <abbr data-tooltip="ddl7ih-tooltip" aria-haspopup="true" class="has-tip" title="" aria-describedby="iopy77-tooltip" data-yeti-box="iopy77-tooltip" data-toggle="iopy77-tooltip" data-resize="iopy77-tooltip" data-events="resize">pps</abbr>
                    </p>
                    <a href="javascript:void(0)" class="enquire-link fancybox-price list-card-price-info" data-fancybox-type="iframe" data-fancybox-href="/pricing/full-day-cape-town-big-five-budget-safari/" data-default-currency="ZAR" data-uid="BLS408" data-eid="31983">Full Pricing Info</a>
                  </div>
                </div>
              </div>
            </div>
          </div>`
        );
      }, "");
      updateCurrencyValues();
    });
}
