<template>
  <div class="filter-group">
    <div class="filter-group__title">{{title}}</div>
    <FilterOption
      v-for="{ label, value, selected, count } in visibleOptions"
      v-bind:key="value"
      :label="label"
      :value="value"
      :is-selected="selected"
      :count="count"
      @filterOptionChanged="handleFilterOptionChange"
    />
    <div @click="toggle" v-if="showToggle" class="filter-group__toggle">{{toggleLabel}}</div>
  </div>
</template>

<script>
import FilterOption from "./FilterOption.vue";
const optionsLimit = 7;
export default {
  props: {
    title: String,
    options: Array,
    listId: String
  },
  components: {
    FilterOption
  },
  computed: {
    visibleOptions() {
      if (this.expanded) {
        return this.options;
      } else {
        return this.options.slice(0, optionsLimit);
      }
    },
    toggleLabel() {
      return this.expanded ? "See less" : "See more";
    },
    showToggle() {
      return this.options.length > optionsLimit;
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    handleFilterOptionChange(value) {
      this.$emit("filterListChange", { value, listId: this.listId });
    }
  },
  data() {
    return {
      expanded: false
    };
  }
};
</script>

<style scoped>
.filter-group {
  margin-bottom: 16px;
  width: 100%;
  padding: 16px 24px;
  background-color: #f0e4d1;
  background: #fff0d1;
}

.filter-group__title {
  color: #5c4646;
  font-weight: bold;
  margin-bottom: 8px;
}

.filter-group__toggle {
  font-size: 14px;
  color: #b95652;
  cursor: pointer;
}
</style>