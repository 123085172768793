<template>
  <div class="full-text-search">
    <div class="search-row">
      <input
        ref="input"
        class="input"
        v-model="userQuery"
        @focus="showSuggestions"
        @blur="hideSuggestions"
        placeholder="Search Destinations, Tours & Safaris, Accommodation, Experiences and Travel Articles"
      />
      <button class="search-button" @click="search">Go →</button>
    </div>
    <div v-if="suggestions.length > 0 && suggestionsVisible" class="suggestions">
      <div
        v-for="suggestion in suggestions"
        :key="suggestion"
        @click="selectOption(suggestion)"
        class="suggest-item"
      >{{ suggestion }}</div>
    </div>
  </div>
</template>

<script>
import { getTourTitles } from "../apiClient";
import Fuse from "fuse.js";

var fuseIndex;

export default {
  data() {
    return {
      userQuery: "",
      suggestionsVisible: false
    };
  },

  computed: {
    suggestions() {
      if (this.userQuery && !this.searchedCurrentQuery) {
        return fuseIndex
          .search(this.userQuery)
          .slice(0, 10)
          .map(r => r.title);
      } else {
        return [];
      }
    }
  },

  methods: {
    selectOption(option) {
      this.userQuery = option;
      this.search();
    },

    search() {
      this.suggestionsVisible = false;
      const event = document.createEvent("Event");
      window.fullTextSearchQuery = this.userQuery;
      event.initEvent("fullTextSearch");
      window.dispatchEvent(event);
      const uri = encodeURI(`/search/results/#/?q=${this.userQuery}`);
      window.location.assign(uri);
    },

    searchOnEnter(e) {
      if (e.keyCode === 13) {
        this.search();
      }
    },

    showSuggestions() {
      this.suggestionsVisible = true;
    },

    hideSuggestions() {
      setTimeout(() => {
        this.suggestionsVisible = false;
      }, 500);
    }
  },

  mounted() {
    getTourTitles().then(tourTitles => {
      fuseIndex = new Fuse(tourTitles, {
        keys: ["title"],
        threshold: 0.3,
        shouldSort: true
      });
    });

    const input = this.$refs.input;
    input.searchOnEnterClicked = this.searchOnEnter.bind(this);
    input.addEventListener("keyup", input.searchOnEnterClicked);
  },

  beforeDestroy() {
    const input = this.$refs.input;
    input.removeEventListener("keyup", input.searchOnEnterClicked);
  }
};
</script>



<style scoped>
.search-row {
  display: flex;
  margin: 32px 64px;
}

.input {
  border: none;
  background: #fff;
  color: #2c3e50;
  font-size: 28px;
  flex-basis: 100%;
}

.search-button {
  border: none;
  background-color: white;
  color: #64553d;
  font-size: 28px;
  flex-shrink: 0;
  flex-basis: content;
}

.suggestions {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  position: absolute;
  background: white;
  font-size: 14px;
  color: #5c4646;
  z-index: 100;
}

.suggest-item {
  cursor: pointer;
  padding: 8px 64px;
}

.suggest-item:hover {
  background-color: rgba(185, 86, 82, 0.2);
}
</style>
