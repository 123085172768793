import { formatCurrency } from "./formatCurrency";
import { formatLabel } from "./formatLabel";
import { getSpecialOffer } from "./getSpecialOffer";
import { currenciesList, convertCurrency } from "./exchangeRates";

/**
 * Get preferd currenct from local storage
 */
const preferredCurrency = localStorage.getItem("currency");
setActiveCurrency(preferredCurrency || "USD");

/**
 * Set initial content
 */
updateCurrencyValues();
updateSpecialOfferLabels();

/**
 * Initialize currency menus
 */
const currencyMenus = document.querySelectorAll(".js-currency-menu");
currencyMenus.forEach(function(currencyMenu) {
  // Open / close menu when trigger is clicked
  const menuTrigger = currencyMenu.querySelector(".js-currency__trigger");
  menuTrigger.innerHTML = formatLabel(getActiveCurrency());
  menuTrigger.addEventListener("click", function() {
    currencyMenu.classList.toggle("is-opened");
  });

  // Fill in available currency options from rates object
  const optionsList = currencyMenu.querySelector(".js-currency__options-list");
  const availableCurrencies = currenciesList();
  availableCurrencies.forEach(function(currency) {
    const currencyButton = document.createElement("div");
    currencyButton.innerHTML = formatLabel(currency);
    currencyButton.classList.add("c-currency-menu__option");
    optionsList.appendChild(currencyButton);
    currencyButton.addEventListener("click", function() {
      selectPreferredCurrency(currency);
      menuTrigger.innerHTML = formatLabel(getActiveCurrency());
    });
  });
});

// If user click anywhere on the document, close the currency menu
document.addEventListener("click", function(e) {
  const currencyMenus = document.querySelectorAll(".js-currency-menu");
  currencyMenus.forEach(function(currencyMenu) {
    const menuTrigger = currencyMenu.querySelector(".js-currency__trigger");
    if (e.target !== menuTrigger) {
      currencyMenu.classList.remove("is-opened");
    }
  });
});

/**
 * Store currenc as prefered and update all existing values
 * @param {String} currency
 */
function selectPreferredCurrency(currency) {
  setActiveCurrency(currency);
  localStorage.setItem("currency", currency);
  updateCurrencyValues();
}

function setActiveCurrency(currency) {
  window.activeCurrency = currency;
  const event = document.createEvent("Event");
  event.initEvent("activeCurrencyChanged");
  window.dispatchEvent(event);
}

/**
 * We want to set currency automatically when user location is detected
 * via it's IP. `locationFromIp.js` can trigger this event.
 */
window.addEventListener("activeCurrencySetFromIp", () => {
  console.log("IP :: event listener triggered");
  selectPreferredCurrency(window.activeCurrency);
  const currencyMenus = document.querySelectorAll(".js-currency-menu");
  currencyMenus.forEach(function(currencyMenu) {
    const menuTrigger = currencyMenu.querySelector(".js-currency__trigger");
    if (menuTrigger) {
      menuTrigger.innerHTML = formatLabel(getActiveCurrency());
    }
  });
});

function getActiveCurrency() {
  return window.activeCurrency;
}

/**
 * Here we replace content of all elements that have a `js-currency` hook class
 * with amount set in `data-amount` attribute converted to currently selected currency.
 * If this element has `data-special-offer-entry-id` attribute, we fetch the corresponding special offer
 * and discount the price.
 */
function updateCurrencyValues() {
  const currencyValues = document.querySelectorAll(".js-currency");
  currencyValues.forEach(function(element) {
    const amount = element.dataset.amount;
    const currency = element.dataset.currency;
    const convertedAmount = convertCurrency(
      amount,
      currency,
      getActiveCurrency()
    );

    const specialOfferEntryId = element.dataset.specialOfferEntryId;
    const specialOffer = getSpecialOffer(specialOfferEntryId);

    if (specialOffer) {
      var discountedPrice = convertedAmount;

      // Apply percent discount if it exists
      const discountPercent =
        Number.parseFloat(specialOffer.special_discount_percentage) / 100;
      if (discountPercent) {
        discountedPrice -= convertedAmount * discountPercent;
      }

      // Apply flat rate discount if it exists
      const discountFlatRate = Number.parseFloat(
        specialOffer.special_discount_flat_rate
      );
      if (discountFlatRate) {
        const discountCurrency = specialOffer.special_currency;
        const convertedDiscountFlatRate = convertCurrency(
          discountFlatRate,
          discountCurrency,
          getActiveCurrency()
        );
        discountedPrice -= convertedDiscountFlatRate;
      }

      //Strikethrough price handling
      const hasOldPrice = element.dataset.oldPrice;
      if (hasOldPrice) {
        //if equals, do not show
        if (discountedPrice == convertedAmount) {
          element.parentNode.innerHTML = "";
          return;
        }
        //show original amount before discount
        element.innerHTML = formatCurrency(
          convertedAmount,
          getActiveCurrency()
        );
        return;
      }
      element.innerHTML = formatCurrency(discountedPrice, getActiveCurrency());
    } else {
      element.innerHTML = formatCurrency(convertedAmount, getActiveCurrency());
    }
  });
}

/**
 * Here we replace content of all elements that have a `js-special-offer-label` hook class
 * with the discount value fetched from the special offer with id indicated in `data-special-offer-entry-id`
 */
function updateSpecialOfferLabels() {
  const specialOfferLabels = document.querySelectorAll(
    ".js-special-offer-label"
  );
  specialOfferLabels.forEach(label => {
    const specialOfferEntryId = label.dataset.specialOfferEntryId;
    const specialOffer = getSpecialOffer(specialOfferEntryId);
    if (specialOffer) {
      const discountPercent = Number.parseFloat(
        specialOffer.special_discount_percentage
      );
      const discountFlatRate = Number.parseFloat(
        specialOffer.special_discount_flat_rate
      );

      if (discountPercent) {
        label.innerHTML = `-${discountPercent}%`;
      } else if (discountFlatRate) {
        const discountCurrency = specialOffer.special_currency;
        const convertedDiscountFlatRate = convertCurrency(
          discountFlatRate,
          discountCurrency,
          getActiveCurrency()
        );
        label.innerHTML = `-${formatCurrency(
          convertedDiscountFlatRate,
          getActiveCurrency()
        )}`;
      } else {
        label.classList.add("list-card-price-discount--full-width");
        label.parentNode.classList.add(
          "list-card-price--package-special-offer"
        );
        label.innerHTML = specialOffer.special_title;
      }
    }
  });
}

export { updateCurrencyValues };
