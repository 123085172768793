import App from "./App.vue";
import PageMain from "./pages/PageMain.vue";

import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import { initializeStore } from "./store";

const searchAppContainer = document.querySelector("#results-app");
if (searchAppContainer) {
  Vue.use(VueRouter);
  const routes = [
    {
      name: "full-price-modal",
      path: "/full-pricing/:tourName",
      component: PageMain
    },
    {
      name: "root",
      path: "/",
      component: PageMain
    }
  ];
  const router = new VueRouter({
    routes
  });

  Vue.config.devtools = true;

  Vue.use(Vuex);

  /**
   * Default values for Vuex store can be passed in DOM
   * via `data-defaults` attribute.
   * This allows us to configure the app while embedding it.
   */
  var storeDefaults = {};
  const rawStoreDefaults = searchAppContainer.dataset.defaults;
  if (rawStoreDefaults) {
    console.log("ResultsApp :: defaults passed", rawStoreDefaults);
    try {
      storeDefaults = JSON.parse(rawStoreDefaults);
    } catch {
      console.error("Could not parse store defaults");
    }
  }
  const store = initializeStore(Vuex, storeDefaults);

  new Vue({
    el: searchAppContainer,
    store,
    router,
    render: h => h(App)
  });

  const fallbackItems = document.querySelectorAll(".js-fallback");
  fallbackItems.forEach(item => {
    item.style.display = "none";
  });
}
