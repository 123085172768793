/**
 * Get current URL query
 * If query is empty, use the default query stored in Vuex
 * @param {*} router
 * @param {*} store
 */
function getQuery(router, store) {
  const route = router.currentRoute;
  const defaultQuery = store.state.defaultUrlQuery;
  const routeQuery = route.query;
  const isRouteQueryEmpty = Object.keys(routeQuery).length === 0;
  return Object.assign({}, isRouteQueryEmpty ? defaultQuery : routeQuery);
}

/**
 * Toggles the value for the URL parameter for given key,
 * which is expected to be a comma separated list of values
 * @param {*} router
 * @param {*} store
 * @param {*} key
 * @param {*} value
 */
function toggleOption(router, store, key, value) {
  // Get existing options for key
  const query = getQuery(router, store);
  const rawValues = query[key];
  const values = rawValues ? rawValues.split(",") : [];

  // Toggle the option in the array
  const index = values.indexOf(value);
  if (index > -1) {
    values.splice(index, 1);
  } else {
    values.push(value);
  }

  // Update the query string
  const valuesString = values.join(",");
  if (valuesString.length > 0) {
    query[key] = valuesString;
  } else {
    delete query[key];
  }

  // Push new route
  setQuery(router, query);
}

/**
 * Updates the URL parameter for given key and sets it to given value
 * @param {*} router
 * @param {*} store
 * @param {*} key
 * @param {*} value
 */
function setOption(router, store, key, value) {
  // Get existing options for key
  const query = getQuery(router, store);

  if (value && value.length > 0) {
    query[key] = value;
  } else {
    delete query[key];
  }

  setQuery(router, query);
}

function setOptions(router, store, options) {
  // Get existing options for key
  const query = getQuery(router, store);

  const keys = Object.keys(options);
  keys.forEach(key => {
    const value = options[key];
    if (value && value.length > 0) {
      query[key] = value;
    } else {
      delete query[key];
    }
  });

  setQuery(router, query);
}

function clearOptions(router, store, keys) {
  const query = getQuery(router, store);

  keys.forEach(key => {
    delete query[key];
  });

  setQuery(router, query);
}

function setDepartureDate(router, store, date, type) {
  const options = {};
  options["departure_type"] = date && type;
  options["departure_date"] = date;
  setOptions(router, store, options);
}

function clearQuery(router) {
  setQuery(router, {});
}

function setQuery(router, query) {
  query.filter = true;
  router.push({
    path: "/",
    query
  });
}

export {
  getQuery,
  toggleOption,
  setOption,
  clearQuery,
  setOptions,
  setDepartureDate,
  clearOptions
};
