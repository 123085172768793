/**
 * This code hooks into an accordion title on tour details page
 * and loads appropriate tour description through an ajax call
 * as the accordion content, when the title is clicked.
 */
import axios from "axios";
var tourTypeDescriptionLoaded = false;
const tourTypeTitle = document.querySelector(".js-what-tour-type__title");
if (tourTypeTitle) {
  tourTypeTitle.addEventListener("mouseover", e => {
    if (!tourTypeDescriptionLoaded) {
      tourTypeDescriptionLoaded = true;
      const tourTypeContent = document.querySelector(
        ".js-what-tour-type__content"
      );
      const tourType = e.target.dataset.tourType;

      axios.get(`/tour-type-descriptions/${tourType}/`).then(response => {
        console.log("Fetching data");
        tourTypeContent.innerHTML = response.data;
      });
    }
  });
}
