<template>
  <div class="tour" :class="{ 'is-expanded': expanded }">
    <div class="tour__images-wrapper">
      <a v-if="imageUrl" :href="viewTourUrl">
        <img :src="imageUrl" class="tour__image" />
      </a>
      <a v-if="mapThumbnail" :href="viewTourUrl">
        <img :src="mapThumbnail" class="tour__image tour__image--map" />
      </a>
    </div>
    <div class="tour__info">
      <div class="tour__title-bar">
        <a :href="viewTourUrl" class="tour__title">{{ tourData.title }}</a>
      </div>
      <div class="tour__content">
        <div class="tour__content-left">
          <div class="tour__content-header">From {{ tourData.tourStart }} to {{ tourData.tourEnd }}</div>
          <p>{{ tourData.description }}</p>
          <div class="tour__content-sub-header">Countries</div>
          <SearchResultTermList :items="tourData.countries" />

          <div class="tour__content-sub-header">Destinations</div>
          <SearchResultTermList :items="tourData.destinations" />

          <div class="tour__content-sub-header">Tour type</div>
          <div class="list">
            <span class="list__item">{{ tourData.type }}</span>
          </div>
        </div>
        <div class="tour__content-right">
          <SearchResultPriceInfo
            :duration="tourData.duration"
            :originalPrice="tourData.price"
            :specialOffer="tourData.specialOffer"
            :urlTitle="tourData.urlTitle"
          />
          <div class="tour__buttons">
            <a class="tour__button tour__button--left" :href="viewTourUrl">View tour</a>
            <a class="tour__button tour__button--right" :href="enquireUrl">Enquire</a>
          </div>
        </div>
      </div>
    </div>
    <div class="tour__toggle-button" @click="toggle">{{ toggleLabel }}</div>
  </div>
</template>

<script>
import SearchResultPriceInfo from "./SearchResultPriceInfo.vue";
import SearchResultTermList from "./SearchResultTermList.vue";
import { buildUrl } from "../../services/buildUrl";
import { convertCurrency } from "../../../js/currency/exchangeRates";

export default {
  props: { tourData: Object },
  components: {
    SearchResultPriceInfo,
    SearchResultTermList
  },
  computed: {
    viewTourUrl() {
      return buildUrl(this.tourData.urlType, this.tourData.urlTitle);
    },
    enquireUrl() {
      return buildUrl(
        "enquire-now",
        `about-${this.tourData.urlType}`,
        this.tourData.urlTitle,
        this.tourData.code
      );
    },
    imageUrl() {
      if (this.tourData.image) {
        const imageNameWithSize = this.tourData.image.replace(".", "__medium.");
        return [
          "https://d2iaf7xwaf71rg.cloudfront.net",
          this.tourData.id,
          imageNameWithSize
        ].join("/");
      }
    },
    mapThumbnail() {
      if (this.tourData.mapImage) {
        const thumbnail = this.tourData.mapImage.replace(".", "_thumb.");
        return [
          "https://www.africanbudgetsafaris.com",
          "images",
          "static_maps",
          thumbnail
        ].join("/");
      }
    },
    toggleLabel() {
      return this.expanded ? "Less information" : "More information";
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    }
  },
  data() {
    return {
      expanded: false
    };
  }
};
</script>

<style scoped>
.tour {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
  background: rgba(65, 59, 46, 0.2);
}

@media (min-width: 768px) {
  .tour {
    flex-direction: row;
  }
}

.tour__title-bar {
  width: 100%;
  padding: 8px 16px;
  background: #1f1001 url("/assets/2019_responsive_update/img/paint.png") center
    bottom repeat-x;
}

.tour__title {
  color: #8fcbde;
  text-shadow: 1px 1px 0 rgb(0, 0, 0);
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.tour__images-wrapper {
  width: 100%;
}

@media (min-width: 768px) {
  .tour__images-wrapper {
    width: 280px;
  }
}

.tour__image {
  width: 100%;
}

.tour__image--map {
  display: none;
}

@media (min-width: 768px) {
  .tour__image--map {
    display: block;
  }
}

.tour__info {
  width: 100%;
}

.tour__content {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  font-size: 14px;
  color: #fbf1d8;
}

@media (min-width: 768px) {
  .tour__content {
    flex-direction: row;
  }
}

.tour__content-header {
  line-height: 20px;
  font-weight: 700;
  color: #ffe4bb;
  margin-bottom: 8px;
}

.tour__content-sub-header {
  line-height: 18px;
  text-transform: uppercase;
  color: #ffe4bb;
}

.tour__content-left {
  display: none;
  box-sizing: border-box;
  width: 100%;
  padding-right: 8px;
  margin-bottom: 16px;
}

.is-expanded .tour__content-left {
  display: block;
}

@media (min-width: 768px) {
  .tour__content-left {
    display: block;
    margin-bottom: 0;
  }
}

.tour__content-right {
  width: 100%;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .tour__content-right {
    width: 240px;
  }
}

.tour__buttons {
  display: flex;
  padding: 8px 0;
}

.tour__button {
  display: inline-block;
  height: 48px;
  width: 100%;
  line-height: 48px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.tour__button--left {
  background-color: #375b34;
  border-radius: 3px 0 0 3px;
}

.tour__button--left:hover {
  background-color: #233b21;
}

.tour__button--right {
  background-color: #477b8c;
  border-radius: 0 3px 3px 0;
}

.tour__button--right:hover {
  background-color: #365d6a;
}

.tour__toggle-button {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-top: 1px solid #423c37;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: #8ab069;
}

@media (min-width: 768px) {
  .tour__toggle-button {
    display: none;
  }
}
</style>
