import { convertCurrency } from "../../../js/currency/exchangeRates";

export function parseSpecialOffers(apiResponse) {
  apiResponse.searchResults.forEach(response => {
    response.specialOffer = parseSpecialOffer(response.specialOffer);
  });

  return apiResponse;
}

/**
 * Special offer is stored as a JSON blob.
 * This function parses JSON and creates a new object that represents the offer.
 * @param {*} json
 */
function parseSpecialOffer(json) {
  if (json) {
    const data = JSON.parse(`[${json}]`);
    const offerData = data.reduce((highestPriorityOffer, currentOffer) => {
      if (
        !highestPriorityOffer ||
        offerPriority(currentOffer) > offerPriority(highestPriorityOffer)
      ) {
        return currentOffer;
      } else {
        return highestPriorityOffer;
      }
    }, null);

    data && data[data.length - 1];
    if (offerData) {
      const discountPercent = Number.parseFloat(
        offerData.special_discount_percentage
      );
      const discountFlatRate = Number.parseFloat(
        offerData.special_discount_flat_rate
      );
      if (discountPercent) {
        return { type: "percent", amount: discountPercent };
      } else if (discountFlatRate) {
        const specialCurrency = offerData.special_currency;
        const amount = convertCurrency(
          discountFlatRate,
          specialCurrency,
          "USD"
        );
        return { type: "flat_rate", amount };
      } else {
        return { type: "free_package", title: offerData.special_title };
      }
    }
  }
}

/**
 * When multiple special offers apply for the same tour
 * we need to decide which one to display.
 * Percent discounts have the highest priority,
 * followed by flat rate discounts,
 * followed by package offers.
 * @param {Object} offer
 */
function offerPriority(offer) {
  const discountPercent = Number.parseFloat(offer.special_discount_percentage);
  const discountFlatRate = Number.parseFloat(offer.special_discount_flat_rate);
  if (discountPercent) {
    return 3;
  } else if (discountFlatRate) {
    return 2;
  } else {
    return 1;
  }
}
