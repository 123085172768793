<template>
  <div class="select-wrapper" :class="themes">
    <select :value="selectedValue" @change="updateStore($event)" class="select">
      <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
    </select>
  </div>
</template>

<script>
import { sortOptions } from "../config/sortOptions";
import { setOption } from "../urlState";

export default {
  props: {
    theme: String
  },

  computed: {
    selectedValue() {
      const sortObject = this.$store.state.sort;
      if (sortObject) {
        return `${sortObject.field}|${sortObject.order}`;
      } else {
        return "relevance";
      }
    },

    options() {
      return sortOptions;
    },

    themes() {
      if (this.theme === "two") {
        return ["theme-two"];
      } else {
        return ["theme-one"];
      }
    }
  },

  methods: {
    updateStore(event) {
      const sortCode = event.target.value;
      setOption(this.$router, this.$store, "sort", sortCode);
    }
  }
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-wrapper.theme-one::after {
  position: absolute;
  right: 8px;
  top: 10px;
  font-size: 12px;
  content: " ▼";
}

.select {
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  transition: none;
  line-height: inherit;
  font-weight: inherit;
  height: auto;
}

.select:focus {
  outline: 0;
}

.theme-one .select {
  display: inline-block;
  width: 220px;
  margin: 0 0 0 16px;
  color: #ffe4bb;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #ffe4bb;
  background: transparent;
}

.theme-one option {
  color: black;
}

.theme-two .select {
  border: none;
  height: 40px;
  text-align-last: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  background-color: #405a38;
  background: transparent;

  cursor: pointer;
}

.theme-two option {
  color: black;
  font-weight: normal;
}
</style>
