<template>
  <div>
    <TheModal :url="modalUrl" v-if="modalUrl" />
    <div class="toggle-menu">
      <div class="toggle-menu__option">
        <SortSelect theme="two" />
      </div>
      <div class="toggle-menu__option" @click="toggleSidebar">Filters</div>
    </div>
    <TheSortBar v-model="sort" />
    <div class="search" :class="{ 'is-sidebar-expanded': isSidebarExpanded }">
      <div class="search__filters">
        <div>
          <div class="filter-group filter-group--no-padding">
            <TheActiveFilters />
          </div>
          <div class="filter-group">
            <div class="filter-group__title">Search titles</div>
            <FullTextSearch />
          </div>
          <div class="filter-group">
            <div class="filter-group__title">Duration</div>
            <FilterSlider
              :value="filterRangeDuration"
              min="1"
              max="56"
              :labelFormatter="daysLabelFormatter"
              @input="updateFilterRangeDuration"
            />
          </div>
          <FilterDepartureDate />
          <FilterPrice
            :currency="currency"
            :value="filterRangePrice"
            @change="updateFilterRangePrice"
          />
          <div class="filter-group">
            <div class="filter-group__title">Start location</div>
            <FilterSelect :options="filterOptionsTourStart" @change="selectFilterOptionTourStart" />
          </div>
          <div class="filter-group">
            <div class="filter-group__title">End location</div>
            <FilterSelect :options="filterOptionsTourEnd" @change="selectFilterOptionTourEnd" />
          </div>
          <div class="filter-group">
            <div class="filter-group__title">Category</div>
            <div v-for="category in filterOptionsCategories" :key="category.value">
              <FilterOption
                :label="category.label"
                :value="category.value"
                :isSelected="category.isSelected"
                :count="0"
                @filterOptionChanged="toggleFilterOptionCategory"
              />
              <div class="filter-group__sub-group">
                <FilterOption
                  v-for="tourType in filterOptionsTourTypesForCategory(category)"
                  :key="tourType.value"
                  :label="tourType.label"
                  :value="tourType.value"
                  :isSelected="tourType.isSelected"
                  :count="0"
                  @filterOptionChanged="toggleFilterOptionTourType"
                />
              </div>
            </div>
          </div>
          <hr />
          <FilterMultiSelect
            title="Destinations"
            :options="filterOptionsDestinations"
            @filterListChange="toggleFilterOptionDestinations"
          />
          <FilterMultiSelect
            title="Countries"
            :options="filterOptionsCountries"
            @filterListChange="toggleFilterOptionCountries"
          />
          <FilterMultiSelect
            title="Accommodation"
            :options="filterOptionsAccommodation"
            @filterListChange="toggleFilterOptionAccommodation"
          />
          <FilterMultiSelect
            title="Activities"
            :options="filterOptionsActivities"
            @filterListChange="toggleFilterOptionActivities"
          />
          <FilterMultiSelect
            title="Wildlife"
            :options="filterOptionsWildlife"
            @filterListChange="toggleFilterOptionWildlife"
          />
          <DivPortal />
        </div>
        <div class="search__see-results-button" @click="toggleSidebar">See results</div>
      </div>
      <div class="search__results">
        <TheResults :filterOptions="filterOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import TheSortBar from "../components/TheSortBar.vue";
import TheResults from "../components/TheResults.vue";
import TheActiveFilters from "../components/TheActiveFilters.vue";
import FullTextSearch from "../components/FullTextSearch.vue";
import SortSelect from "../components/SortSelect.vue";
import FilterSlider from "../components/FilterSlider.vue";
import FilterList from "../components/FilterList.vue";
import FilterMultiSelect from "../components/FilterMultiSelect.vue";
import FilterPrice from "../components/FilterPrice.vue";
import FilterSelect from "../components/FilterSelect.vue";
import FilterOption from "../components/FilterOption.vue";
import FilterDepartureDate from "../components/FilterDepartureDate.vue";
import DivPortal from "../components/DivPortal.vue";
import TheModal from "../components/TheModal.vue";

import { generateFilters } from "../config/filters";
import { format } from "date-fns";
import { getQuery, toggleOption, setOption } from "../urlState";

const listFiltersUrlKeys = [
  "categories",
  "tourTypes",
  "tourStart",
  "tourEnd",
  "countries",
  "destinations",
  "accommodation",
  "activities",
  "wildlife"
];

export default {
  components: {
    TheResults,
    TheSortBar,
    TheActiveFilters,
    SortSelect,
    FilterSlider,
    FilterList,
    FilterMultiSelect,
    FilterPrice,
    FullTextSearch,
    FilterSelect,
    FilterOption,
    TheModal,
    FilterDepartureDate,
    DivPortal
  },

  data() {
    return {
      filterGroups: [],
      price: null,
      isSidebarExpanded: false,
      modalUrl: null
    };
  },

  computed: {
    filterRangeDuration() {
      return this.$store.state.filterRangeDuration;
    },

    filterRangePrice() {
      return this.$store.state.filterRangePrice;
    },

    filterOptionsCategories() {
      return this.$store.state.filterOptions.categories;
    },

    filterOptionsTourTypes() {
      return this.$store.state.filterOptions.tourTypes;
    },

    filterOptionsTourStart() {
      return this.$store.state.filterOptions.tourStart;
    },

    tourStart() {
      return this.filterOptionsTourStart.find(l => l.isSelected);
    },

    filterOptionsTourEnd() {
      return this.$store.state.filterOptions.tourEnd;
    },

    tourEnd() {
      return this.filterOptionsTourEnd.find(l => l.isSelected);
    },

    filterOptionsCountries() {
      return this.$store.state.filterOptions.countries;
    },

    filterOptionsDestinations() {
      return this.$store.state.filterOptions.destinations;
    },

    filterOptionsAccommodation() {
      return this.$store.state.filterOptions.accommodation;
    },

    filterOptionsActivities() {
      return this.$store.state.filterOptions.activities;
    },

    filterOptionsWildlife() {
      return this.$store.state.filterOptions.wildlife;
    },

    filterOptions() {
      return {
        sort: this.sort,
        currency: this.currency,
        duration: this.filterRangeDuration,
        price: this.filterRangePrice,
        fullTextQuery: this.fullTextQuery,
        categories: this.filterOptionsCategories,
        tourTypes: this.filterOptionsTourTypes,
        departure: this.departure,
        tourStart: this.tourStart,
        tourEnd: this.tourEnd,
        countries: this.filterOptionsCountries,
        destinations: this.filterOptionsDestinations,
        accommodation: this.filterOptionsAccommodation,
        activities: this.filterOptionsActivities,
        wildlife: this.filterOptionsWildlife
      };
    },

    departure() {
      return this.$store.state.departureDateFilter;
    },

    sort() {
      return this.$store.state.sort;
    },

    currency() {
      return this.$store.state.currency;
    },

    fullTextQuery() {
      return this.$store.state.fullTextQuery;
    }
  },

  methods: {
    updateFilterRangeDuration(value) {
      setOption(this.$router, this.$store, "duration", value.join("|"));
    },

    updateFilterRangePrice(newValue) {
      const codedValue = `${newValue.min}|${newValue.max}|${newValue.type}`;
      setOption(this.$router, this.$store, "price", codedValue);
    },

    toggleFilterOptionCategory(value) {
      toggleOption(this.$router, this.$store, "categories", value);
    },

    toggleFilterOptionTourType(value) {
      toggleOption(this.$router, this.$store, "tourTypes", value);
    },

    toggleFilterOptionCountries(value) {
      toggleOption(this.$router, this.$store, "countries", value);
    },

    toggleFilterOptionDestinations(value) {
      toggleOption(this.$router, this.$store, "destinations", value);
    },

    toggleFilterOptionAccommodation(value) {
      toggleOption(this.$router, this.$store, "accommodation", value);
    },

    toggleFilterOptionActivities(value) {
      toggleOption(this.$router, this.$store, "activities", value);
    },

    toggleFilterOptionWildlife(value) {
      toggleOption(this.$router, this.$store, "wildlife", value);
    },

    selectFilterOptionTourStart(value) {
      setOption(this.$router, this.$store, "tourStart", value);
    },

    selectFilterOptionTourEnd(value) {
      setOption(this.$router, this.$store, "tourEnd", value);
    },

    filterOptionsTourTypesForCategory(category) {
      return this.$store.state.filterOptions.tourTypes.filter(
        tourTypeFilter =>
          category.value === tourTypeFilter.parentCategory &&
          (category.isSelected || tourTypeFilter.isSelected)
      );
    },

    toggleSidebar() {
      this.isSidebarExpanded = !this.isSidebarExpanded;
    },

    daysLabelFormatter(x) {
      return `${x} day${x > 1 ? "s" : ""}`;
    },

    syncStateFromRoute(route) {
      if (route.name === "full-price-modal") {
        this.modalUrl = `/pricing/${route.params.tourName}/`;
      } else {
        this.modalUrl = null;
      }

      // Sync VueX to URL params
      if (route.name === "root") {
        const urlState = getQuery(this.$router, this.$store);

        // List filters
        listFiltersUrlKeys.forEach(key => {
          this.$store.commit("selectFilterOptions", {
            key,
            selectedValues: urlState[key]
          });
        });

        // Sort
        if (urlState.sort === "relevance") {
          this.$store.commit("setSort", null);
        } else if (urlState.sort) {
          const [field, order] = urlState.sort.split("|");
          this.$store.commit("setSort", { field, order });
        } else {
          this.$store.commit("setSort", {
            field: "tour_duration",
            order: "asc"
          });
        }

        // Full-text search
        this.$store.commit("fullTextSearch", urlState.q);

        // Duration
        this.$store.commit(
          "setFilterRangeDuration",
          urlState.duration &&
            urlState.duration.split("|").map(n => Number.parseInt(n))
        );

        // Price
        let priceObject = null;
        if (urlState.price) {
          const [min, max, type] = urlState.price.split("|");
          priceObject = {
            min: Number.parseInt(min),
            max: Number.parseInt(max),
            type
          };
        }
        this.$store.commit("setFilterRangePrice", priceObject);

        // Departure
        const departureDateProp = urlState["departure_date"];
        const departureTypeProp = urlState["departure_type"];

        let departureDate = null;
        let departureType = null;

        if (departureTypeProp === "today") {
          departureDate = format(new Date(), "yyyyMMdd");
          departureType = "DAY";
        } else if (departureTypeProp === "day") {
          departureDate = departureDateProp;
          departureType = "DAY";
        } else if (departureTypeProp === "day_plus_3") {
          departureDate = departureDateProp;
          departureType = "DAY_PLUS_3";
        } else if (departureTypeProp === "months") {
          departureDate = departureDateProp.split(",");
          departureType = "MONTHS";
        }

        let departureFilterObject = {};
        if (departureDate && departureType) {
          if (departureType === "MONTHS") {
            departureFilterObject = {
              type: departureType,
              months: departureDate
            };
          } else {
            departureFilterObject = {
              type: departureType,
              date: departureDate
            };
          }
        }
        this.$store.commit("setDepartureDateFilter", departureFilterObject);
      }
    }
  },

  mounted() {
    // Connect currency with external currency selection menu
    const initialCurrency = window.activeCurrency || "USD";
    this.$store.commit("setCurrency", initialCurrency);
    window.addEventListener("activeCurrencyChanged", () => {
      const currency = window.activeCurrency;
      this.$store.commit("setCurrency", currency);
    });

    generateFilters().then(filterGroups => {
      console.log("FILTER GROUPS", filterGroups);
      // New Query based filter options
      listFiltersUrlKeys.forEach(key => {
        const group = filterGroups.find(g => g.key === key);
        this.$store.commit("setFilterOptions", {
          key,
          options: group.options
        });
      });

      const initialRoute = this.$store.state.initialRoute;
      if (initialRoute) {
        this.$router.push({ name: initialRoute });
      }
      this.syncStateFromRoute(this.$route);
    });
  },

  watch: {
    $route(to, from) {
      console.log("Route change", from, "=>", to);
      this.syncStateFromRoute(to);
    }
  }
};
</script>

<style scoped>
.filter-group {
  margin-bottom: 16px;
  width: 100%;
  padding: 16px 24px;
  background-color: lightgoldenrodyellow;
  background: #e2d7c0;
}

.filter-group--no-padding {
  padding: 0;
}

.filter-group__title {
  color: #5c4646;
  font-weight: bold;
  margin-bottom: 8px;
}

.filter-group__sub-group {
  margin-left: 16px;
}

.search {
  display: flex;
}

.search__filters {
  width: 100%;
  margin-right: 30px;
  display: none;
}

.search__filters {
  display: none;
}

@media (max-width: 1023px) {
  .is-sidebar-expanded .search__filters {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 60;
    overflow: scroll;
    background-color: #1f1001;
    padding: 8px;
    display: block;
  }

  .is-sidebar-expanded .search__filters .filter-group:last-child {
    margin-bottom: 48px;
  }
}

@media (min-width: 1024px) {
  .search__filters {
    width: 300px;
    display: block;
  }
}

.search__results {
  width: 100%;
}

.is-sidebar-expanded .search__results {
  display: none;
}

@media (min-width: 1024px) {
  .search__results {
    display: block;
  }
}

.is-sidebar-expanded .search__see-results-button {
  background-color: #b95652;
  color: white;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

@media (min-width: 1024px) {
  .search__see-results-button {
    display: none;
  }
}

.toggle-menu {
  position: sticky;
  display: flex;
  top: 0;
  margin-bottom: 16px;
  z-index: 25;
}

.toggle-menu__option {
  border: none;
  width: 100%;
  height: 40px;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  background-color: #405a38;
  line-height: 40px;
  cursor: pointer;
}

.toggle-menu__option:hover {
  background-color: #2c3e27;
}

.toggle-menu__option:first-child {
  border-right: 1px solid #b8c5b4;
}

@media (min-width: 1024px) {
  .toggle-menu {
    display: none;
  }
}
</style>
