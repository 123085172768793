<template>
  <div class="full-text-search">
    <div class="search-row">
      <input class="input" v-model="userQuery" @focus="showSuggestions" @blur="hideSuggestions" />
      <button class="search-button" @click="search">Go</button>
    </div>
    <div v-if="suggestions.length > 0 && suggestionsVisible" class="suggestions">
      <div
        v-for="suggestion in suggestions"
        :key="suggestion"
        @click="selectOption(suggestion)"
        class="suggest-item"
      >{{ suggestion }}</div>
    </div>
  </div>
</template>

<script>
import { getTourTitles } from "../../apiClient";
import Fuse from "fuse.js";
import { setOptions } from "../urlState";

var fuseIndex;

export default {
  data() {
    return {
      userQuery: "",
      suggestionsVisible: false
    };
  },
  computed: {
    suggestions() {
      if (this.userQuery && !this.searchedCurrentQuery) {
        return fuseIndex
          .search(this.userQuery)
          .slice(0, 10)
          .map(r => r.title);
      } else {
        return [];
      }
    },

    searchedCurrentQuery() {
      return this.$store.state.fullTextQuery === this.userQuery;
    }
  },

  mounted() {
    getTourTitles().then(tourTitles => {
      fuseIndex = new Fuse(tourTitles, {
        keys: ["title"],
        threshold: 0.3,
        shouldSort: true
      });

      this.readQueryFromUrl();
    });

    window.addEventListener("fullTextSearch", () => {
      this.selectOption(window.fullTextSearchQuery);
    });
  },

  methods: {
    selectOption(option) {
      this.userQuery = option;
      this.search();
    },

    search() {
      setOptions(this.$router, this.$store, {
        q: this.userQuery,
        sort: "relevance"
      });
    },

    showSuggestions() {
      this.suggestionsVisible = true;
    },

    hideSuggestions() {
      setTimeout(() => {
        this.suggestionsVisible = false;
      }, 500);
    },

    readQueryFromUrl() {
      const urlQuery = this.$route.query.q;
      if (urlQuery) {
        this.userQuery = urlQuery;
        this.search();
      }
    }
  }
};
</script>

<style scoped>
.search-row {
  display: flex;
}

.input {
  border: 1px solid #80736f;
  color: #5c4646;
  width: 100%;
  padding: 4px;
}

.search-button {
  padding: 0 8px;
  background-color: #5c4646;
  color: white;
}

.suggestions {
  max-height: 400px;
  overflow: auto;
  position: absolute;
  background: #f0e5d1;
  border: 1px solid #80736f;
  font-size: 14px;
  color: #5c4646;
  z-index: 100;
}

.suggest-item {
  cursor: pointer;
  padding: 4px 8px;
}

.suggest-item:hover {
  background-color: rgba(185, 86, 82, 0.2);
}
</style>
