/**
 * Returns currency name with appropriate flag image
 */
export function formatLabel(currency) {
  return (
    '<img class="c-currency-menu__flag" src="/assets/img/ui/flags/' +
    currency +
    '.svg">' +
    currency
  );
}
