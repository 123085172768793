<style scoped>
.currency-prefix {
  font-size: 0.75em;
  font-style: normal;
  padding-right: 0.1em;
}
</style>

<template>
  <span>
    <span class="currency-prefix" v-if="formattedCurrency.prefix">
      {{
      formattedCurrency.prefix
      }}
    </span>
    {{ formattedCurrency.value }}
  </span>
</template>

<script>
import { formatCurrency } from "../../services/formatCurrency";
import { convertCurrency } from "../../../js/currency/exchangeRates";

export default {
  props: { valueInUSD: Number },
  data() {
    return {
      currency: window.activeCurrency || "USD"
    };
  },
  computed: {
    formattedCurrency() {
      return formatCurrency(this.convertedValue, this.currency);
    },
    convertedValue() {
      return convertCurrency(this.valueInUSD, "USD", this.currency);
    }
  },
  mounted() {
    window.addEventListener("activeCurrencyChanged", () => {
      this.currency = window.activeCurrency;
    });
  }
};
</script>
