<template>
  <div class="wrapper" :class="{'is-expanded': optionsListVisible}">
    <div class="current-value" @click="toggleOptionsList">{{valueOrPlaceholder}}</div>
    <div class="options-list">
      <input v-model="filterText" class="input" ref="filterInput" @blur="closeOptionsList" />
      <div v-if="value" class="options-list__item" @click="selectOption(null)">Any</div>
      <div
        v-for="option in filteredOptions"
        :key="option.value"
        class="options-list__item"
        @click="selectOption(option.value)"
      >{{option.label}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array
  },

  data() {
    return {
      filterText: null,
      optionsListVisible: false
    };
  },

  methods: {
    toggleOptionsList() {
      this.optionsListVisible = !this.optionsListVisible;
      this.filterText = null;
      if (this.optionsListVisible) {
        setTimeout(() => {
          this.$refs.filterInput.focus();
        }, 100);
      }
    },

    selectOption(value) {
      this.$emit("change", value);
      this.closeOptionsList();
      this.optionsListVisible = false;
      this.filterText = null;
    },

    closeOptionsList() {
      setTimeout(() => {
        this.optionsListVisible = false;
        this.filterText = null;
      }, 300);
    }
  },

  computed: {
    filteredOptions() {
      return this.options.filter(
        option =>
          !this.filterText ||
          option.label.toLowerCase().includes(this.filterText.toLowerCase())
      );
    },

    value() {
      return this.options.find(o => o.isSelected);
    },

    valueOrPlaceholder() {
      return (this.value && this.value.label) || "Any";
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: relative;
}

.current-value {
  height: 36px;
  line-height: 36px;
  padding: 0 8px;
  border: 1px solid #80736f;
  border-radius: 3px;
  font-size: 14px;
  color: #5c4646;
  background: #f0e5d1;
  cursor: pointer;
}

.options-list__item {
  cursor: pointer;
  padding: 0 4px;
}

.options-list__item:hover {
  background-color: rgba(185, 86, 82, 0.2);
}

.options-list {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 2;
  border: 1px solid #80736f;
  border-radius: 3px;
  background-color: white;
}

.is-expanded .options-list {
  display: block;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  padding: 4px;
}
</style>