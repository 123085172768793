export const sortOptions = [
  {
    label: "Duration: Shortest first",
    value: "tour_duration|asc"
  },
  {
    label: "Duration: Longest first",
    value: "tour_duration|desc"
  },
  {
    label: "Total price: Lowest first",
    value: "tour_price_usd|asc"
  },
  {
    label: "Total price: Highest first",
    value: "tour_price_usd|desc"
  },
  {
    label: "Price per day: Lowest first",
    value: "tour_price_daily_usd|asc"
  },
  {
    label: "Price per day: Highest first",
    value: "tour_price_daily_usd|desc"
  },
  {
    label: "Relevance",
    value: "relevance"
  }
];
