<template>
  <div class="filter-option">
    <span class="filter-option__clear-button" @click="handleClearButtonClick">✕</span>
    <em>{{groupTitle}}:</em>
    {{optionLabel}}
  </div>
</template>

<script>
export default {
  props: {
    groupTitle: String,
    groupKey: String,
    optionLabel: String,
    optionValue: String
  },

  methods: {
    handleClearButtonClick() {
      console.log("Emitting clearFilter");
      this.$emit("clearFilter", {
        value: this.optionValue,
        groupKey: this.groupKey
      });
    }
  }
};
</script>

<style scoped>
.filter-option {
  color: #5c4646;
  font-size: 14px;
  line-height: 18px;
}

.filter-option__clear-button {
  display: inline-block;
  margin-right: 4px;
  cursor: pointer;
}
</style>