import axios from "axios";
import { buildSearchQuery } from "./buildSearchQuery";
import { parseSpecialOffers } from "./parseSpecialOffers";

export function search(filterOptions, page = 0) {
  console.log("API Client :: search initialized");
  const query = buildSearchQuery(filterOptions, page);
  console.log("API Client :: built query", JSON.stringify(query));

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: "https://europe-west1-elasticsafari.cloudfunctions.net/search",
      data: query,
      timeout: 5000,
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        console.log("API Client :: got response", response);
        const parsedResponse = parseSpecialOffers(response.data);
        resolve(parsedResponse);
      })
      .catch(reject);
  });
}
