/**
 * Check local storage and global `nrRates` object, and pick the one that's newer.
 * If current page rates are newer, update the local storage values.
 */
const localStorageRatesRaw = localStorage.getItem("rates");
const localStorageRates = JSON.parse(localStorageRatesRaw);
const currentPageRates = nrRates;
var rates;

if (localStorageRates && currentPageRates) {
  if (currentPageRates.ds > localStorageRates.ds) {
    localStorage.setItem("rates", currentPageRates);
    rates = currentPageRates;
  } else {
    rates = localStorageRates;
  }
} else if (localStorageRates) {
  rates = localStorageRates;
} else if (currentPageRates) {
  rates = currentPageRates;
} else {
  console.error("Could not find rates object.");
}

function getRate(currency) {
  return rates[currency.toUpperCase()];
}

/**
 * Converts given amount from source to target currency
 * @param {Number} amount
 * @param {String} sourceCurrency
 * @param {String} targetCurrency
 */
function convertCurrency(amount, sourceCurrency, targetCurrency) {
  const sourceRate = getRate(sourceCurrency);
  const targetRate = getRate(targetCurrency);
  return (amount / sourceRate) * targetRate;
}

function currenciesList() {
  return Object.keys(rates).filter(value => value != "ds");
}

export { getRate, convertCurrency, currenciesList };
