/**
 * Converts filter options into an ES query
 * @param {Object} options
 */
export function buildSearchQuery(options = {}, page = 0) {
  const query = {};

  if (page) {
    query["page"] = page;
  }

  if (options.duration) {
    query["duration"] = options.duration;
  }

  if (options.price) {
    const fullPriceOptions = options.price;
    fullPriceOptions.currency = options.currency;
    query["price"] = fullPriceOptions;
  }

  if (options.fullTextQuery) {
    query["fullTextQuery"] = options.fullTextQuery;
  }

  if (options.categories) {
    const selectedOptions = options.categories.filter(e => e.isSelected);
    if (selectedOptions.length > 0) {
      query.categories = selectedOptions.map(o => o.value);
    }
  }

  if (options.tourTypes) {
    console.log("Tour types are selected", options.tourTypes);
    const selectedOptions = options.tourTypes.filter(e => e.isSelected);
    // Family safaris behave as sub-categories (tour types)
    // but are actually regular categories
    const familySafaris = selectedOptions.filter(
      e => e.parentCategory === "154"
    );
    if (familySafaris.length > 0) {
      query.categories = familySafaris
        .map(o => o.value)
        .concat(query.categories);
    }
    const otherSafaris = selectedOptions.filter(
      e => e.parentCategory !== "154"
    );
    if (otherSafaris.length > 0) {
      query.tourTypes = otherSafaris.map(o => o.value);
    }
  }

  if (options.countries) {
    const selectedOptions = options.countries.filter(e => e.isSelected);
    if (selectedOptions.length > 0) {
      query.countries = selectedOptions.map(o => o.value);
    }
  }

  if (options.destinations) {
    const selectedOptions = options.destinations.filter(e => e.isSelected);
    if (selectedOptions.length > 0) {
      query.destinations = selectedOptions.map(o => o.value);
    }
  }

  if (options.accommodation) {
    const selectedOptions = options.accommodation.filter(e => e.isSelected);
    if (selectedOptions.length > 0) {
      query.accommodation = selectedOptions.map(o => o.value);
    }
  }

  if (options.activities) {
    const selectedOptions = options.activities.filter(e => e.isSelected);
    if (selectedOptions.length > 0) {
      query.activities = selectedOptions.map(o => o.value);
    }
  }

  if (options.wildlife) {
    const selectedOptions = options.wildlife.filter(e => e.isSelected);
    if (selectedOptions.length > 0) {
      query.wildlife = selectedOptions.map(o => o.value);
    }
  }

  if (options.tourStart) {
    query.tourStart = options.tourStart.value;
  }

  if (options.tourEnd) {
    query.tourEnd = options.tourEnd.value;
  }

  if (options.sort) {
    query["sort"] = options.sort;
  }

  if (options.departure) {
    query["departure"] = options.departure;
  }

  return query;
}
