<template>
  <div>
    <div class="header">
      <div>Filter by:</div>
      <div v-if="activeFiltersCount" class="header__clear-button" @click="clearAllFilters">Clear all</div>
    </div>
    <div v-if="activeFiltersCount" class="filters-list">
      <div class="sub-header">{{ filtersCountLabel }}</div>
      <TheActiveFiltersOption
        v-for="activeFilter in activeFiltersList"
        :key="activeFilter.title"
        :groupTitle="activeFilter.title"
        :groupKey="activeFilter.groupKey"
        :optionLabel="activeFilter.label"
        :optionValue="activeFilter.value"
        @clearFilter="clearFilter"
      />
    </div>
  </div>
</template>

<script>
import TheActiveFiltersOption from "./TheActiveFiltersOption.vue";
import { toggleOption, clearQuery, clearOptions } from "../urlState";

const vuexListFilters = [];

export default {
  components: { TheActiveFiltersOption },

  computed: {
    activeFiltersCount() {
      return this.activeFiltersList.length;
    },

    activeFiltersList() {
      const list = [];

      this.selectedCategories.forEach(({ label, value }) => {
        list.push({
          title: "Category",
          groupKey: "categories",
          label,
          value
        });
      });

      this.selectedTourTypes.forEach(({ label, value }) => {
        list.push({
          title: "Tour type",
          groupKey: "tourTypes",
          label,
          value
        });
      });

      if (this.selectedTourStart) {
        list.push({
          title: "Tour start",
          groupKey: "tourStart",
          label: this.selectedTourStart.label,
          value: this.selectedTourStart.value
        });
      }

      if (this.selectedTourEnd) {
        list.push({
          title: "Tour end",
          groupKey: "tourEnd",
          label: this.selectedTourStart.label,
          value: this.selectedTourStart.value
        });
      }

      this.selectedCountries.forEach(({ label, value }) => {
        list.push({
          title: "Country",
          groupKey: "countries",
          label,
          value
        });
      });

      this.selectedDestinations.forEach(({ label, value }) => {
        list.push({
          title: "Destination",
          groupKey: "destinations",
          label,
          value
        });
      });

      this.selectedAccommodation.forEach(({ label, value }) => {
        list.push({
          title: "Accommodation",
          groupKey: "accommodation",
          label,
          value
        });
      });

      this.selectedActivities.forEach(({ label, value }) => {
        list.push({
          title: "Activity",
          groupKey: "activities",
          label,
          value
        });
      });

      this.selectedWildlife.forEach(({ label, value }) => {
        list.push({
          title: "Wildlife",
          groupKey: "wildlife",
          label,
          value
        });
      });

      if (this.selectedFullTextQuery) {
        list.push({
          title: "Title",
          groupKey: "q",
          label: this.selectedFullTextQuery,
          value: this.selectedFullTextQuery
        });
      }

      if (this.selectedDuration) {
        const label = `${this.selectedDuration[0]} - ${this.selectedDuration[1]}`;
        const value = `${this.selectedDuration[0]}|${this.selectedDuration[1]}`;
        list.push({
          title: "Duration",
          groupKey: "duration",
          label: label,
          value: value
        });
      }

      if (this.selectedPrice) {
        const label = `${this.selectedPrice.min} - ${this.selectedPrice.max}`;
        const value = `${this.selectedPrice.min}|${this.selectedPrice.max}|${this.selectedPrice.type}`;
        list.push({
          title: "Price",
          groupKey: "price",
          label: label,
          value: value
        });
      }

      if (this.departureDate) {
        const departureDate = this.departureDate;
        if (departureDate) {
          if (departureDate.type === "DAY") {
            list.push({
              title: "Departure",
              groupKey: "departure",
              label: "Day specified",
              value: departureDate.date
            });
          } else if (departureDate.type === "DAY_PLUS_3") {
            list.push({
              title: "Departure",
              groupKey: "departure",
              label: "Day specified +/-3",
              value: departureDate.date
            });
          } else if (departureDate.type === "MONTHS") {
            list.push({
              title: "Departure",
              groupKey: "departure",
              label: "Months specified",
              value: departureDate.months.join(",")
            });
          }
        }
      }

      return list;
    },

    filtersCountLabel() {
      if (this.activeFiltersCount === 1) {
        return "1 filter applied";
      } else {
        return `${this.activeFiltersCount} filters applied`;
      }
    },

    selectedCategories() {
      return this.$store.state.filterOptions.categories.filter(
        o => o.isSelected
      );
    },

    selectedTourTypes() {
      return this.$store.state.filterOptions.tourTypes.filter(
        o => o.isSelected
      );
    },

    selectedTourStart() {
      return this.$store.state.filterOptions.tourStart.find(l => l.isSelected);
    },

    selectedTourEnd() {
      return this.$store.state.filterOptions.tourEnd.find(l => l.isSelected);
    },

    selectedCountries() {
      return this.$store.state.filterOptions.countries.filter(
        l => l.isSelected
      );
    },

    selectedDestinations() {
      return this.$store.state.filterOptions.destinations.filter(
        l => l.isSelected
      );
    },

    selectedAccommodation() {
      return this.$store.state.filterOptions.accommodation.filter(
        l => l.isSelected
      );
    },

    selectedActivities() {
      return this.$store.state.filterOptions.activities.filter(
        l => l.isSelected
      );
    },

    selectedWildlife() {
      return this.$store.state.filterOptions.wildlife.filter(l => l.isSelected);
    },

    selectedFullTextQuery() {
      return this.$store.state.fullTextQuery;
    },

    selectedDuration() {
      return this.$store.state.filterRangeDuration;
    },

    selectedPrice() {
      return this.$store.state.filterRangePrice;
    },

    departureDate() {
      return this.$store.state.departureDateFilter;
    }
  },

  methods: {
    clearFilter({ groupKey, value }) {
      if (groupKey === "departure") {
        clearOptions(this.$router, this.$store, [
          "departure_type",
          "departure_date"
        ]);
      } else {
        toggleOption(this.$router, this.$store, groupKey, value);
      }
    },

    clearAllFilters() {
      clearQuery(this.$router);
    }
  }
};
</script>

<style scoped>
.header {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  background-color: #b95652;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.header__clear-button {
  color: #b95652;
  background-color: white;
  padding: 4px 6px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
}

.filters-list {
  padding: 16px 24px;
}

.sub-header {
  font-weight: 700;
  font-size: 14px;
  color: #5c4646;
}
</style>
