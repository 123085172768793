<template>
  <div class="modal-frame">
    <div class="modal-frame__close" @click="close">✕</div>
    <LoadingSpinner v-if="loading" />
    <iframe class="iframe" :class="{ 'iframe--loading': loading }" @load="iframeLoaded" :src="url" />
  </div>
</template>

<script>
import LoadingSpinner from "../components/LoadingSpinner.vue";

export default {
  props: {
    url: String
  },

  components: {
    LoadingSpinner
  },

  data() {
    return {
      loading: true
    };
  },

  watch: {
    url() {
      this.loading = true;
    }
  },

  methods: {
    close() {
      this.$router.go(-1);
    },

    closeOnEsceape(e) {
      if (e.keyCode === 27) {
        this.close();
      }
    },

    iframeLoaded() {
      this.loading = false;
    }
  },

  mounted() {
    window.keyupListener = this.closeOnEsceape.bind(this);
    window.addEventListener("keyup", window.keyupListener);
  },

  beforeDestroy() {
    window.removeEventListener("keyup", window.keyupListener);
  }
};
</script>

<style scoped>
.modal-frame {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.modal-frame__close {
  position: absolute;
  top: 8px;
  right: 24px;
  font-size: 32px;
  color: white;
  cursor: pointer;
}

.iframe {
  width: 100%;
  height: 100%;
}

.iframe--loading {
  display: none;
}
</style>
