import { fetchUrl } from "../../services/fetchUrl";
import categories from "./categories.json";
import tourTypes from "./tourTypes.json";

/**
 * Generate list of filters
 */
export function generateFilters() {
  return new Promise(resolve => {
    fetchUrl({
      url: "/_global/elastic_facets_data/",
      cacheKey: "filters",
      cacheHours: 24
    }).then(response => {
      const filterGroups = response
        .map(group => {
          return {
            title: group.title,
            key: group.key,
            options: group.options.map(f => ({
              label: f.name,
              value: f.value || f.name,
              count: f.count,
              selected: false
            }))
          };
        })
        .filter(g => !["tourTypes", "categories"].includes(g.key));

      const categoriesFilter = categories.map(c =>
        Object.assign({ isSelected: false }, c)
      );
      filterGroups.unshift({
        title: "Categories",
        key: "categories",
        options: categoriesFilter
      });

      const tourTypesFilter = tourTypes.map(t =>
        Object.assign({ isSelected: false }, t)
      );
      filterGroups.unshift({
        title: "Tour Types",
        key: "tourTypes",
        options: tourTypesFilter
      });

      resolve(filterGroups);
    });
  });
}
