export const priceFilterOptions = [
  {
    currency: "EUR",
    type: "TOUR",
    minPrice: 1,
    maxPrice: 10000
  },
  {
    currency: "EUR",
    type: "PER_DAY",
    minPrice: 1,
    maxPrice: 1000
  },
  {
    currency: "GBP",
    type: "TOUR",
    minPrice: 1,
    maxPrice: 9000
  },
  {
    currency: "GBP",
    type: "PER_DAY",
    minPrice: 1,
    maxPrice: 2000
  },
  {
    currency: "USD",
    type: "TOUR",
    minPrice: 1,
    maxPrice: 10000
  },
  {
    currency: "USD",
    type: "PER_DAY",
    minPrice: 20,
    maxPrice: 1100
  },
  {
    currency: "ZAR",
    type: "TOUR",
    minPrice: 1,
    maxPrice: 150000
  },
  {
    currency: "ZAR",
    type: "PER_DAY",
    minPrice: 1,
    maxPrice: 17000
  },
  {
    currency: "AUD",
    type: "TOUR",
    minPrice: 1,
    maxPrice: 15000
  },
  {
    currency: "AUD",
    type: "PER_DAY",
    minPrice: 1,
    maxPrice: 1600
  },
  {
    currency: "CAD",
    type: "TOUR",
    minPrice: 1,
    maxPrice: 14000
  },
  {
    currency: "CAD",
    type: "PER_DAY",
    minPrice: 1,
    maxPrice: 1500
  },
  {
    currency: "NZD",
    type: "TOUR",
    minPrice: 1,
    maxPrice: 16000
  },
  {
    currency: "NZD",
    type: "PER_DAY",
    minPrice: 1,
    maxPrice: 1700
  }
];
