<template>
  <div class="price-info" :class="{ 'price-info--free-package': freePackageOffer }">
    <div class="price-info__duration">
      {{durationLabel}}
      <span v-if="showOriginalPrice" class="price-info__original-price">
        <MonetaryValue v-if="originalPrice" :valueInUSD="originalPrice" />
      </span>
    </div>
    <div class="price-info__price">
      <MonetaryValue v-if="originalPrice" :valueInUSD="price" />
      <span v-else>no price</span>
    </div>
    <span
      v-if="percentDiscountOffer"
      class="price-info__special-offer-label"
    >-{{ specialOffer.amount }}%</span>
    <span v-if="flatDiscountOffer" class="price-info__special-offer-label">
      -
      <MonetaryValue :valueInUSD="specialOffer.amount" />
    </span>
    <span
      v-if="freePackageOffer"
      class="price-info__special-offer-label price-info__special-offer-label--full-width"
    >{{ specialOffer.title }}</span>
    <RouterLink class="price-info__full-info" rel="nofollow" :to="fullPriceUrl">Full Pricing Info</RouterLink>
  </div>
</template>

<script>
import MonetaryValue from "./MonetaryValue.vue";

export default {
  props: {
    specialOffer: Object,
    originalPrice: Number,
    duration: Number,
    urlTitle: String
  },

  components: { MonetaryValue },

  computed: {
    showOriginalPrice() {
      return this.specialOffer && !this.freePackageOffer;
    },

    percentDiscountOffer() {
      return this.specialOfferType === "percent";
    },

    flatDiscountOffer() {
      return this.specialOfferType === "flat_rate";
    },

    freePackageOffer() {
      return this.specialOfferType === "free_package";
    },

    price() {
      const specialOffer = this.specialOffer;
      if (specialOffer) {
        if (specialOffer.type === "percent") {
          return (
            this.originalPrice -
            (specialOffer.amount / 100) * this.originalPrice
          );
        } else if (specialOffer.type === "flat_rate") {
          return this.originalPrice - specialOffer.amount;
        }
      }
      return this.originalPrice;
    },

    specialOfferType() {
      return this.specialOffer && this.specialOffer.type;
    },

    fullPriceUrl() {
      return `/full-pricing/${this.urlTitle}/`;
    },

    durationLabel() {
      return this.duration === 1 ? "1 day from" : `${this.duration} days from`;
    }
  }
};
</script>

<style scoped>
.price-info {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 24px;
  background: rgba(0, 0, 0, 0.2);
}

.price-info--free-package {
  padding-bottom: 48px;
}

.price-info__duration {
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 24px;
  color: #fbf1d8;
}

.price-info__original-price {
  text-decoration: line-through;
  font-size:80%;
}

.price-info__price {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  color: #e4c888;
}

.price-info__special-offer-label {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 8px;
  color: white;
  background-color: #973733;
}

.price-info__special-offer-label--full-width {
  left: 0;
}

.price-info__full-info {
  color: #7fb260;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}
</style>
