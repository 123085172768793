<template>
  <div class="filter-group">
    <div class="filter-group__title">Budget</div>
    <div class="filter-price__switch">
      <div
        class="filter-price__switch-option"
        :class="{'is-selected': tourPricingActive}"
        @click="tourPricing"
      >Whole tour</div>
      <div
        class="filter-price__switch-option"
        :class="{'is-selected': perDayPricingActive}"
        @click="perDayPricing"
      >Per day</div>
    </div>
    <FilterSlider
      v-for="slider in activeSliders"
      :key="slider.currency + slider.type"
      v-model="slider.price"
      :min="slider.minPrice"
      :max="slider.maxPrice"
      :labelFormatter="labelFormatter(slider.currency)"
      @input="triggerChangeEvent"
    />
  </div>
</template>

<script>
import FilterSlider from "./FilterSlider.vue";
import { formatCurrency } from "../../services/formatCurrency";
import { priceFilterOptions } from "../config/priceFilterOptions";

export default {
  components: {
    FilterSlider
  },
  props: ["value", "currency"],
  data() {
    return {
      type: "TOUR",
      sliders: priceFilterOptions.map(o =>
        Object.assign(o, { price: [o.minPrice, o.maxPrice] })
      )
    };
  },
  computed: {
    filterOptions() {
      return {
        type: this.type,
        min: this.activeSliders[0].price[0],
        max: this.activeSliders[0].price[1]
      };
    },
    tourPricingActive() {
      return this.type === "TOUR";
    },
    perDayPricingActive() {
      return this.type === "PER_DAY";
    },
    activeSliders() {
      return this.sliders.filter(
        s => s.currency === this.currency && s.type === this.type
      );
    }
  },
  methods: {
    tourPricing() {
      this.type = "TOUR";
      this.triggerChangeEvent();
    },
    perDayPricing() {
      this.type = "PER_DAY";
      this.triggerChangeEvent();
    },
    labelFormatter(currency) {
      return x => {
        const formatted = formatCurrency(x, currency);
        if (formatted.prefix) {
          return formatted.prefix + formatted.value;
        } else {
          return formatted.value;
        }
      };
    },
    triggerChangeEvent() {
      this.$emit("change", this.filterOptions);
    }
  },
  watch: {
    value(newValue) {
      console.log("FilterPrice value change", newValue);
      if (newValue) {
        this.type = newValue.type;
        this.activeSliders.forEach(slider => {
          slider.price = [newValue.min, newValue.max];
        });
      } else {
        this.type = "TOUR";
        this.activeSliders.forEach(slider => {
          slider.price = [slider.minPrice, slider.maxPrice];
        });
      }
    }
  }
};
</script>

<style scoped>
.filter-group__title {
  color: #5c4646;
  font-weight: bold;
  margin-bottom: 8px;
}

.filter-price__switch {
  display: flex;
  margin-bottom: 16px;
}

.filter-price__switch-option {
  background: #f0e5d1;
  border: 1px solid #80736f;
  border-radius: 3px;
  text-align: center;
  height: 36px;
  line-height: 36px;
  width: 100%;
  font-size: 14px;
  color: #5c4646;
  cursor: pointer;
}

.filter-price__switch-option.is-selected {
  background: rgba(185, 86, 82, 0.2);
  border-color: #b95652;
  font-weight: 700;
  color: #5c4646;
}

.filter-price__switch-option:first-child {
  margin-right: 4px;
}
</style>