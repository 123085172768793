import axios from "axios";
import cookies from "js-cookie";

console.log("IP :: detecting location...");

const countryCookie = cookies.get("country");

if (countryCookie) {
  console.log("IP :: country cookie found", countryCookie);
  setPhoneByCountry(countryCookie);
} else {
  const ipField = document.querySelector("#client_ip");
  const ip = ipField && ipField.value;
  console.log("IP :: read from field", ip);
  if (ip) {
    const accessKey = "40eaeda8d8e69b7c158102ad295e63d2";
    const ipStackUrl = `https://api.ipstack.com/${ip}?access_key=${accessKey}`;
    axios.get(ipStackUrl).then(response => {
      // Get country
      console.log("IP :: response from ipstack", response);
      const data = response && response.data;
      const country = data && data.country_code;
      console.log("IP :: country code", country);
      cookies.set("country", country, { expires: 1 });

      setPhoneByCountry(country);

      // Get currency
      const currency = data && data.currency && data.currency.code;
      console.log("IP :: currency code", currency);
      if (currency) {
        setCurrency(currency);
      }
    });
  }
}

function setPhoneByCountry(countryCode) {
  if (countryCode === "CA" || countryCode === "US") {
    showPhoneNumber("na");
  } else if (countryCode === "UK") {
    showPhoneNumber("uk");
  }
}

function showPhoneNumber(type) {
  const phoneNumbers = document.querySelectorAll(".js-phone-number");
  phoneNumbers.forEach(number => number.classList.add("h-hide"));
  document
    .querySelector(`.js-phone-number--${type}`)
    .classList.remove("h-hide");
}

function setCurrency(currency) {
  const preferredCurrency = localStorage.getItem("currency");
  if (preferredCurrency) {
    console.log("IP :: preferred currency already set", preferredCurrency);
  } else {
    const availableCurrencies = [
      "EUR",
      "GBP",
      "USD",
      "ZAR",
      "AUD",
      "CAD",
      "NZD"
    ];
    if (availableCurrencies.includes(currency)) {
      console.log("IP :: setting currency", currency);
      window.activeCurrency = currency;
      const event = document.createEvent("Event");
      event.initEvent("activeCurrencySetFromIp");
      window.dispatchEvent(event);
    }
  }
}
