/**
 * Converts a list of path elements to a full URL (including protocol and domain name)
 *
 * This function depends on back-end setting up `baseUrl` variable on `window`.
 * In EE you can do it like this: `<script>window.baseUrl = "{site_url}";</script>`
 * @param  {...any} pathElements
 */
export function buildUrl(...pathElements) {
  const base = window.baseUrl || "";
  const path = pathElements.join("/");
  return base + path + "/";
}
