/**
 * Formats the given monetary value
 * @param {Number} amount
 * @param {String} currency
 */
export function formatCurrency(unformattedAmount, unformattedCurrency) {
  const amount = Math.round(unformattedAmount)
    .toString()
    .split("")
    .reverse()
    .reduce(function(acc, current, index, array) {
      acc.push(current);
      const thirdDigit = (index + 1) % 3 === 0;
      const lastDigit = index + 1 === array.length;
      if (thirdDigit && !lastDigit) {
        acc.push(",");
      }
      return acc;
    }, [])
    .reverse()
    .join("");

  const currency = unformattedCurrency.toUpperCase();
  if (currency === "GBP") {
    return { value: "£" + amount };
  } else if (currency === "EUR") {
    return { value: "€" + amount };
  } else if (currency === "USD") {
    return { value: "$" + amount };
  } else if (currency === "ZAR") {
    return { value: "R" + amount };
  } else if (currency === "AUD") {
    return { prefix: "AU", value: "$" + amount };
  } else if (currency === "CAD") {
    return { prefix: "CA", value: "$" + amount };
  } else if (currency === "NZD") {
    return { prefix: "NZ", value: "$" + amount };
  } else {
    console.warn("Using default format for currency:", currency);
    return currency + " " + amount;
  }
}
