<template>
  <div class="list">
    <span>
      <span v-for="item in visibleItems" v-bind:key="item" class="list__item">{{item}}</span>
    </span>
    <span v-if="showExpandButton" @click="expand" class="list__expand">+{{hiddenItemsCount}} more</span>
  </div>
</template>

<script>
const itemsLimit = 3;
export default {
  props: { items: Array },
  computed: {
    visibleItems() {
      if (this.expanded) {
        return this.items;
      } else {
        return this.items.slice(0, itemsLimit);
      }
    },
    hiddenItemsCount() {
      return this.items.length - itemsLimit;
    },
    showExpandButton() {
      return !this.expanded && this.hiddenItemsCount > 0;
    }
  },
  methods: {
    expand() {
      this.expanded = true;
    }
  },
  data() {
    return {
      expanded: false
    };
  }
};
</script>

<style scoped>
.list {
  margin-bottom: 8px;
}

.list__item {
  display: inline-block;
  margin-right: 4px;
}

.list__item:not(:last-child)::after {
  content: ",";
}

.list__expand {
  color: #7fb260;
  cursor: pointer;
  white-space: nowrap;
}
</style>