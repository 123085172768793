import App from "./App.vue";

import Vue from "vue";

const searchAppContainer = document.querySelector("#header-search-app");
if (searchAppContainer) {
  new Vue({
    el: searchAppContainer,
    render: h => h(App)
  });
}

window.addEventListener("fullTextSearch", () => {
  const wrapper = document.querySelector(".site-nav-search-wrapper");
  if (wrapper) {
    wrapper.classList.remove("show-search");
  }
});
