<template>
  <div class="filter-group">
    <div class="filter-group__title">Departure date</div>
    <div class="departure-date__months-list">
      <FilterOption
        v-for="month in monthsList"
        :key="month"
        :label="month.label"
        :value="month.value"
        :isSelected="month.isSelected"
        @filterOptionChanged="toggleMonth"
      />
      <div class="departure-date__toggle" @click="toggleMonthsList">{{expandListLabel}}</div>
    </div>
    <Datepicker :value="date" @input="selectDate" placeholder="Select a date" :clear-button="true" />
    <div>
      <input
        type="checkbox"
        name="type"
        id="type_day_plus_3"
        v-model="expandDaySearch"
        :disabled="!date"
      />
      <label class="departure-date__label" for="type_day_plus_3">+/-3 days</label>
    </div>
  </div>
</template>

<script>
import FilterOption from "./FilterOption.vue";
import Datepicker from "vuejs-datepicker";
import { zeroPad } from "../helpers/zeroPad";
import { format, add, parse } from "date-fns";
import { setDepartureDate } from "../urlState";

export default {
  data() {
    return {
      monthsListExpanded: false,
      expandDaySearch: false
    };
  },

  components: {
    Datepicker,
    FilterOption
  },

  methods: {
    toggleMonthsList() {
      this.monthsListExpanded = !this.monthsListExpanded;
    },

    toggleMonth(value) {
      const selectedMonths = this.monthsList
        .filter(
          o =>
            (o.isSelected && o.value !== value) ||
            (!o.isSelected && o.value === value)
        )
        .map(o => o.value)
        .join(",");

      setDepartureDate(this.$router, this.$store, selectedMonths, "months");
    },

    selectDate(date) {
      if (date) {
        const encodedDate = format(date, "yyyyMMdd");
        const type = this.expandDaySearch ? "day_plus_3" : "day";

        setDepartureDate(this.$router, this.$store, encodedDate, type);
      } else {
        setDepartureDate(this.$router, this.$store, null, null);
      }
    }
  },

  computed: {
    monthsList() {
      const today = new Date();
      const formatLabel = date => format(date, "MMM yyyy");
      const formatValue = date => format(date, "yyyyMM");
      const list = [...Array(this.monthsListLength).keys()]
        .map(offset => add(today, { months: offset }))
        .map(date => ({
          label: formatLabel(date),
          value: formatValue(date),
          isSelected: false
        }));

      if (this.type === "MONTHS") {
        const departureDateFilter = this.$store.state.departureDateFilter;
        list.forEach(month => {
          month.isSelected = departureDateFilter.months.includes(month.value);
        });
      }

      return list;
    },

    monthsListLength() {
      return this.monthsListExpanded ? 18 : 8;
    },

    expandListLabel() {
      return this.monthsListExpanded ? "See less" : "See more";
    },

    date() {
      const dateString =
        this.$store.state.departureDateFilter &&
        this.$store.state.departureDateFilter.date;
      if (dateString) {
        return parse(dateString, "yyyyMMdd", new Date());
      }
    },

    type() {
      return (
        this.$store.state.departureDateFilter &&
        this.$store.state.departureDateFilter.type
      );
    }
  },

  mounted() {
    this.expandDaySearch = !!(this.type && this.type === "DAY_PLUS_3");
  },

  watch: {
    type(newValue) {
      this.expandDaySearch = !!(newValue === "DAY_PLUS_3");
    },

    expandDaySearch() {
      if (this.type === "DAY" || this.type === "DAY_PLUS_3") {
        this.selectDate(this.date);
      }
    }
  }
};
</script>

<style>
.filter-group__title {
  color: #5c4646;
  font-weight: bold;
  margin-bottom: 8px;
}

.departure-date__months-list {
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #5c4646;
}

[type="checkbox"] + .departure-date__label {
  margin: 0;
  color: #5c4646;
}

.departure-date__toggle {
  font-size: 14px;
  color: #b95652;
  cursor: pointer;
}

.vdp-datepicker input {
  position: relative;
  margin-bottom: 4px;
  height: 36px;
  line-height: 36px;
  padding: 0 8px;
  border: 1px solid #80736f;
  border-radius: 3px;
  font-size: 14px;
  color: #5c4646;
  background: #f0e5d1;
  cursor: pointer;
}

.vdp-datepicker input:focus {
  background: #f0e5d1;
}

.vdp-datepicker input::placeholder {
  color: #5c4646;
}

.vdp-datepicker__clear-button {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 24px;
  color: #5c4646;
}
</style>
