export function initializeStore(vuex, defaults = {}) {
  return new vuex.Store({
    state: {
      initialRoute: defaults["initialRoute"],
      searchResultsCount: null,
      currency: "USD",
      sort: null,
      fullTextQuery: null,
      departureDateFilter: null,
      filterRangeDuration: null,
      filterRangePrice: null,
      filterOptions: {
        categories: [],
        tourTypes: [],
        tourStart: [],
        tourEnd: [],
        countries: [],
        destinations: [],
        accommodation: [],
        activities: [],
        wildlife: []
      },
      defaultUrlQuery: defaults["query"] || {}
    },

    mutations: {
      selectFilterOptions(state, { key, selectedValues }) {
        const valuesArray = selectedValues ? selectedValues.split(",") : [];
        state.filterOptions[key].forEach(option => {
          option.isSelected = valuesArray.includes(option.value);
        });

        // Sometimes we need to link to filter options that are not shown by default
        // To cover this, if selected option is not listed, we add it here
        const existingValues = state.filterOptions[key].map(o => o.value);
        const selectedUnlistedValues = valuesArray.filter(
          value => !existingValues.includes(value)
        );
        selectedUnlistedValues.forEach(selectedValue => {
          state.filterOptions[key].unshift({
            label: selectedValue,
            value: selectedValue,
            isSelected: true
          });
        });
      },

      setFilterOptions(state, { key, options }) {
        state.filterOptions[key] = options.map(o => ({
          label: o.label,
          value: o.value,
          isSelected: o.isSelected,
          count: o.count,
          parentCategory: o.parentCategory
        }));
      },

      setSort(state, options) {
        state.sort = options;
      },

      setCurrency(state, value) {
        state.currency = value;
      },

      fullTextSearch(state, payload) {
        state.fullTextQuery = payload;
      },

      setFilterRangeDuration(state, payload) {
        state.filterRangeDuration = payload;
      },

      setFilterRangePrice(state, payload) {
        state.filterRangePrice = payload;
      },

      updateResultsCount(state, payload) {
        state.searchResultsCount = payload;
      },

      setDepartureDateFilter(state, payload) {
        state.departureDateFilter = payload;
      },

      clearAllOptionsForSelectFilter(state, key) {
        state[key].forEach(option => (option.isSelected = false));
      }
    }
  });
}
