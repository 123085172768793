import axios from "axios";

export function getTourTitles() {
  console.log("API Client :: getTourTitles initialized");
  return new Promise(resolve => {
    var hasFreshCache = false;

    const cachedResponseJSON = localStorage.getItem("tourTitles");
    const cachedResponse = cachedResponseJSON && JSON.parse(cachedResponseJSON);
    if (cachedResponse) {
      const cacheAge = Math.floor(
        (Date.now() - cachedResponse.cachedOn) / (1000 * 60 * 60 * 24)
      );
      if (cacheAge < 3) {
        hasFreshCache = true;
      }
    }

    if (hasFreshCache) {
      console.log("API Client :: getTourTitles resolved from cache");
      resolve(cachedResponse.tourTitles);
    } else {
      axios({
        method: "get",
        url:
          "https://europe-west1-elasticsafari.cloudfunctions.net/tour_titles",
        timeout: 5000
      }).then(response => {
        console.log("API Client :: getTourTitles got results", response);
        const tourTitles = response.data.map(title => ({ title }));

        const storageObject = JSON.stringify({
          cachedOn: Date.now(),
          tourTitles
        });
        localStorage.setItem("tourTitles", storageObject);

        resolve(tourTitles);
      });
    }
  });
}
