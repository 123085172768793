var specialOffers;

/**
 * Returns a special offer object for given tour.
 * If there are multiple matching offers, it will return the one with higest priority.
 * @param {String} tourId
 */
export function getSpecialOffer(tourId) {
  if (typeof specialsData !== "undefined") {
    if (!specialOffers) {
      const currentTime = Math.floor(Date.now() / 1000);
      // Remove empty offer objects and expired offers
      specialOffers = specialsData
        .filter(e => e)
        .flat()
        .filter(offer => offer.special_expiration_date > currentTime);
    }

    const offersForThisTour = specialOffers.filter(
      o => o.tour_entry_id === tourId
    );

    return offersForThisTour.reduce((highestPriorityOffer, currentOffer) => {
      if (
        !highestPriorityOffer ||
        offerPriority(currentOffer) > offerPriority(highestPriorityOffer)
      ) {
        return currentOffer;
      } else {
        return highestPriorityOffer;
      }
    }, null);
  }
}

/**
 * When multiple special offers apply for the same tour
 * we need to decide which one to display.
 * Percent discounts have the highest priority,
 * followed by flat rate discounts,
 * followed by package offers.
 * @param {Object} offer
 */
function offerPriority(offer) {
  const discountPercent = Number.parseFloat(offer.special_discount_percentage);
  const discountFlatRate = Number.parseFloat(offer.special_discount_flat_rate);
  if (discountPercent) {
    return 3;
  } else if (discountFlatRate) {
    return 2;
  } else {
    return 1;
  }
}
