<style scoped>
.labels {
  display: flex;
  justify-content: space-between;
  color: #5c4646;
  font-size: 12px;
  line-height: 20px;
}

.filter-slider >>> .vue-slider-rail {
  background-color: #e6e6e6;
}

.filter-slider >>> .vue-slider-process {
  background-color: #5292a0;
}
</style>

<template>
  <div class="filter-slider">
    <vue-slider
      v-model="sliderValue"
      :min="min"
      :max="max"
      tooltip="none"
      @change="updateModel"
      @drag-start="dragStart"
      @drag-end="dragEnd"
    />
    <div class="labels">
      <div>{{ this.labelLow }}</div>
      <div>{{ this.labelHigh }}</div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  components: {
    VueSlider
  },
  props: {
    value: Array,
    labelFormatter: Function,
    min: Number,
    max: Number
  },
  data() {
    return {
      isDragging: false
    };
  },
  computed: {
    labelLow() {
      if (this.labelFormatter) {
        return this.labelFormatter(this.sliderValue[0]);
      } else {
        return this.sliderValue[0];
      }
    },
    labelHigh() {
      if (this.labelFormatter) {
        return this.labelFormatter(this.sliderValue[1]);
      } else {
        return this.sliderValue[1];
      }
    }
  },
  data() {
    return {
      sliderValue: this.value || [this.min, this.max]
    };
  },
  methods: {
    dragStart() {
      this.isDragging = true;
    },
    dragEnd() {
      this.isDragging = false;
      this.updateModel();
    },
    updateModel() {
      if (!this.isDragging) {
        this.$emit("input", this.sliderValue);
      }
    }
  },
  watch: {
    value(newValue) {
      this.sliderValue = newValue || [this.min, this.max];
    }
  }
};
</script>
