<template>
  <div>
    <div v-if="isLoading" class="spinner-wrapper">
      <LoadingSpinner />
    </div>
    <div v-if="isResultsVisible" class="results">
      <SearchResult v-for="result in results" v-bind:key="result.title" :tourData="result" />
    </div>
    <div
      v-if="isEmptyStateVisible"
      class="empty-state"
    >
    <h1>Search Results - No Trips Found</h1>
    <p>Aw, sorry, no results for that search.</p>

    <p><strong>WAIT. DON'T GIVE UP!</strong></p>

    <p>We can help you find exactly what you are looking for and then some. We've got nearly 600 trips throughout Africa and we custom-make safaris too.</p>
    <ul>
    <li>Send us a quick email <a href="https://www.africanbudgetsafaris.com/private-group-safaris/enquire/">https://www.africanbudgetsafaris.com/enquire-now/</a></li>
    <li>LiveChat with us right now (click the chat bubble at the bottom-right of the page).</li>
    </ul>
    <p>We'll get you matching results in a flash.</p>

    <p><hr class="hr"></p>

    <p><strong>TALK TO HUMANS (very friendly)</strong></p>

    <p>We're here to answer your questions and help you find the African safari that's right for you and your budget, even if it doesn’t exist yet... Yup, we can tailor-make a safari just for you, and on a budget too!</p>
    <p>For private group tours and tailor-made safaris - hit this and we'll get you sorted out chop-chop.</p>
    <p><a href="https://www.africanbudgetsafaris.com/private-group-safaris/enquire/">https://www.africanbudgetsafaris.com/private-group-safaris/enquire/</a></p>

    <p><hr class="hr"></p>

    <p><strong>OR, TRY AGAIN!</strong></p>
    <p>Try searching again.</p>

    <h5>Search Tips</h5>

    <ol>
      <li>
        <p>If in doubt, leave duration out.</p>
        <p>Sometimes the places you want to visit are just too far apart to fit into a trip of a few days. We can help stretch your budget but thousands of miles take some time to cover ;) If you want to visit Cape Town, Kruger and Victoria Falls, for instance, you’ll need at least two weeks.</p>
      </li>
      <li>
        <p>Still in doubt, leave more filters out.</p>
        <p>We have capable people who can help whittle your options down later, so try broadening your search.</p>
      </li>
      <li>
        <p>Reorder the results once you get them.</p>
        <p>Rather than filtering for duration and price in your search, you can reorder the tours by duration (long to short or short to long) or price (low to high or high to low) once you get them.</p>
      </li>
    </ol>

    <p><strong>We know Africa. Let us take you there!</strong></p>

    </div>
    <div v-if="isErrorVisible">
      <div class="error">An error has occured, please try again later.</div>
      <a class="button" @click="initialFetch">Try again</a>
    </div>
    <div v-if="isLoadMoreVisible" class="load-more">
      <a class="button" @click="fetchMore">Load more results</a>
    </div>
  </div>
</template>

<script>
import { search } from "../../apiClient";
import SearchResult from "./SearchResult.vue";
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
  props: { filterOptions: Object },
  components: {
    SearchResult,
    LoadingSpinner
  },
  data() {
    return {
      page: 0,
      results: [],
      state: "loading",
      hasMoreResults: true
    };
  },

  computed: {
    isLoading() {
      return this.state === "loading";
    },

    isResultsVisible() {
      return this.results.length > 0;
    },

    isEmptyStateVisible() {
      return this.results.length === 0 && this.state === "ok";
    },

    isErrorVisible() {
      return this.state === "error";
    },

    isLoadMoreVisible() {
      return this.hasMoreResults && this.state === "ok";
    }
  },

  watch: {
    filterOptions: {
      handler: function(oldQ, newQ) {
        this.initialFetch();
      },
      deep: true
    }
  },
  methods: {
    initialFetch() {
      this.page = 0;
      this.fetchTours().then(searchResults => {
        this.results = searchResults;
      });
    },

    fetchMore() {
      this.page += 1;
      this.fetchTours().then(searchResults => {
        this.results = this.results.concat(searchResults);
      });
    },

    fetchTours() {
      console.log("fetchTours() has been called");
      this.state = "loading";
      return new Promise(resolve => {
        if (this.filterOptions) {
          search(this.filterOptions, this.page)
            .then(({ totalCount, searchResults }) => {
              this.hasMoreResults = searchResults.length === 10;
              this.state = "ok";
              this.$store.commit("updateResultsCount", totalCount);
              resolve(searchResults);
            })
            .catch(e => {
              console.error("ERROR ::", e);
              this.state = "error";
            });
        } else {
          resolve([]);
        }
      });
    }
  }
};
</script>

<style scoped>
.spinner-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.load-more {
  text-align: center;
  padding: 16px 0 24px 0;
}

.button {
  display: inline-block;
  color: #ffe4bb;
  cursor: pointer;
  padding: 8px 16px;
}

.button:hover {
  background-color: #b95652;
}

.error {
  margin-bottom: 16px;
  color: #b95652;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.results {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}

.empty-state {
  font-size: 24px;
  padding: 32px;
  text-align: center;
  color: #b95652;
}

@media (min-width: 768px) {
  .results {
    display: block;
  }
}
</style>