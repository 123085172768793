<template>
  <div class="sidebar-widgets">
    <div ref="content"></div>
  </div>
</template>

<script>
import LoadingSpinner from "../components/LoadingSpinner.vue";

export default {
  props: {
    selector: String
  },

  data() {
    return {
      content: ""
    };
  },

  mounted() {
    const portalContentSource = document.querySelector(".js-div-portal");
    if (portalContentSource) {
      this.$refs.content.innerHTML = portalContentSource.innerHTML;
    }
  }
};
</script>

