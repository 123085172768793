<template>
  <div class="sort-bar">
    <div>{{resultsMetaData}}</div>
    <div class="sort-bar__sort-wrapper">
      Sort by
      <SortSelect theme="one" />
    </div>
  </div>
</template>

<script>
import SortSelect from "./SortSelect.vue";

export default {
  components: {
    SortSelect
  },

  computed: {
    resultsMetaData() {
      const count = this.$store.state.searchResultsCount;
      if (count) {
        return `${count} African safari packages`;
      } else {
        return "500+ African safari packages";
      }
    }
  }
};
</script>

<style scoped>
.sort-bar {
  display: none;
  height: 80px;
  color: #ffe4bb;
  font-size: 20px;
  line-height: 24px;
  padding: 0 16px;
  background: #232019 url("/assets/2019_responsive_update/img/mud-darl.jpg")
    left top repeat;
}

@media (min-width: 1024px) {
  .sort-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.sort-bar__sort-wrapper {
  display: flex;
  align-items: center;
}
</style>
