// Make sure cache is cleared if it is older than this day
// because APIs have updated and cache is now indvalid
const staleCacheDate = 1580483492002;

export function fetchUrl(options) {
  const { url, type = "GET", cacheKey, cacheHours } = options;

  // Load from cache if caching
  if (cacheKey) {
    const cachedResponseJSON = localStorage.getItem(cacheKey);
    const cachedResponse = cachedResponseJSON && JSON.parse(cachedResponseJSON);
    if (cachedResponse) {
      const cacheAge = Math.floor(
        (Date.now() - cachedResponse.cachedOn) / (1000 * 60 * 60)
      );
      if (cacheAge < cacheHours && cachedResponse.cachedOn > staleCacheDate) {
        return new Promise(resolve => {
          resolve(cachedResponse.data);
        });
      }
    }
  }

  return new Promise(resolve => {
    $.ajax({
      url,
      type,
      success: function(response) {
        const parsedResponse = JSON.parse(response);

        // Store to cache
        if (cacheKey) {
          const cacheObject = JSON.stringify({
            cachedOn: Date.now(),
            data: parsedResponse
          });
          localStorage.setItem(cacheKey, cacheObject);
        }

        resolve(parsedResponse);
      }
    });
  });
}
